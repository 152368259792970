<template>
  <div class="flex items-center px-2 lg:px-0">
    <div class="hidden sm:flex flex-shrink-0 items-center font-proxima text-white text-sm font-semibold">
      <button @click="changeModule">
        <img class="h-16 w-auto" src="~/assets/images/top_logo.png" alt="TSAMM Logo" />
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import {useRuntimeConfig, useSessionStore, useUserStore} from "#imports";

const config = useRuntimeConfig();
const userStore = useUserStore();
const sessionStore = useSessionStore();

const changeModule = () => {
  userStore.clearUser();
  userStore.setNotifChecked(false);
  sessionStore.stopCountdownTimer();
  clearUserSession();
  userStore.clearUser();
  window.location = config.public.moduleUrl;
};
</script>
