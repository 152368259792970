<template>
  <footer class="bg-black print:hidden">
    <div class="mx-auto px-2 sm:px-4 lg:px-8">
      <div class="flex h-16 justify-between">
        <LayoutFooterLogo />
        <!--        <LayoutFooterLanguage />-->
      </div>
    </div>
    <div class="text-gray-600 text-[10px] text-right px-2">Version {{ version }}</div>
  </footer>
</template>
<script setup lang="ts">
const config = useRuntimeConfig();

const version = ref(config.public.clientVersion);
</script>
