<template>
  <div class="mx-auto flex items-center pt-4">
    <a href="https://www.dataparadigm.com/" target="_blank">
      <div class="hidden sm:flex flex-shrink-0 items-center font-proxima text-white text-sm">
        <img class="w-auto mr-4" src="~/assets/images/dpi.png" alt="Your Company" />
        <span class="hidden sm:flex"> Powered by Data Paradigm, Inc. </span>
      </div>
    </a>
  </div>
</template>
